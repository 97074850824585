/* landingPage.css */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }
  
  button {
    text-transform: none;
  }
  
  @keyframes borderColorChange {
    0% {
      border-color: #ff6347;
    }
    25% {
      border-color: #3cb371;
    }
    50% {
      border-color: #1e90ff;
    }
    75% {
      border-color: #ff69b4;
    }
    100% {
      border-color: #ff6347;
    }
  }
  
  .apply-now-button {
    color: #1976d2;
    padding: 10px 20px;
    font-size: 18px;
    border: 3px solid;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
    outline: none;
    animation: borderColorChange 2s infinite;
    font-weight: 700;
  }
  
  .apply-now-button:hover {
    background-color: #1565c0;
    color: white;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: Arial, sans-serif;
  }
  
  .container {
    width: 100%;
    height: 100%;
  }
  
  /* Navbar */
  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 50px;
    background-color: #333;
    color: white;
  }
  
  .navbar .left {
    display: flex;
  }
  
  .navbar .logo {
    height: 40px;
    margin-right: 20px;
  }
  
  .navbar .nav-links {
    list-style: none;
    display: flex;
  }
  
  .navbar .nav-links li {
    margin-right: 20px;
    cursor: pointer;
  }
  
  .navbar .right button {
    margin-left: 20px;
    padding: 5px 10px;
  }
  
  /* Banner */
  .banner {
    background-image: url('../../assets/images/background-image.jpeg');
    background-size: cover;
    height: 400px;
    color: white;
    text-align: center;
    position: relative;
  }
  
  .banner .banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .banner h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .countdown-timer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .countdown-timer div {
    margin-right: 20px;
  }
  
  .book-now-btn {
    background-color: #b000b5;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  /* Privilege Club Pop-up */
  .privilege-club {
    position: absolute;
    top: 150px;
    right: 50px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  
  .privilege-club h2 {
    margin-bottom: 10px;
  }
  
  .privilege-club ul {
    list-style: none;
  }
  
  .privilege-club ul li {
    margin-bottom: 10px;
  }
  
  .join-now-btn {
    background-color: #b000b5;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  /* Manage / Check-in Section */
  .manage-section {
    padding: 50px;
  }
  
  .manage-checkin {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
  }
  
  .tabs {
    display: flex;
    margin-bottom: 20px;
  }
  
  .tabs button {
    margin-right: 20px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .form {
    display: flex;
    gap: 10px;
  }
  
  .booking-ref,
  .last-name {
    padding: 10px;
    width: 200px;
  }
  
  .retrieve-booking {
    padding: 10px 20px;
    background-color: #b000b5;
    color: white;
    border: none;
    cursor: pointer;
  }

  /* Hero flight styling */

  /* Container */
.container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

/* Banner Section */
.banner {
  background-color: #0073e6;
  padding: 50px 0;
  text-align: center;
}

.banner h1 {
  color: white;
  font-size: 36px;
  font-weight: bold;
}

/* Privilege Club Pop-up */
.privilege-club {
  background-color: #f7f7f7;
  padding: 30px;
  margin: 30px auto;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.privilege-club h2 {
  color: #0073e6;
  margin-bottom: 20px;
}

.privilege-club ul {
  list-style-type: none;
  padding: 0;
  color: #333;
  margin-bottom: 20px;
}

.privilege-club ul li {
  margin-bottom: 10px;
}

.join-now-btn {
  background-color: #0073e6;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.join-now-btn:hover {
  background-color: #005bb5;
}

/* Services Section */
.services-section {
  background-color: #ffffff;
  padding: 40px 0;
  text-align: center;
}

.services-title {
  color: #333;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 40px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}

.service-box {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  width: 100%;
  max-width: 350px;
}

.service-box h3 {
  color: #0073e6;
  font-size: 22px;
  margin-bottom: 15px;
}

.service-box p {
  color: #555;
  font-size: 16px;
  line-height: 1.6;
}

.service-box:hover {
  background-color: #e9f4ff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
}

  