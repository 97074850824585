/* General container styling */
.overview-container {
  margin-top: 50px;
  padding: 20px 40px;

}

/* Button styling */
.country-button {
  padding: 12px 24px;
  border-radius: 25px;
  border: 2px solid #1C6BD3;
  background-color: transparent;
  color: #1C6BD3;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;
  margin-top: 150px;
}

.country-button:hover {
  background-color: #1C6BD3;
  color: white;
}

/* Flex container styling */
.button-container {
  display: flex;
  gap: 15px;
  margin-bottom: 40px;
  justify-content: center;
  position: relative;
  z-index: 1;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .overview-container {
    padding: 10px 20px;
  }

  .country-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}
